<template>
  <div class="team-page-wrapper">
    <TsParticles />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="sub-title text-left">TEAM</p>
          <h1 class="text-left title">Meet the Team.</h1>
          <p class="text-left description">
            Our mission is to build a resilient, transparent community that
            strengthens one another.
          </p>
          <h2 class="mt-48">Our Team</h2>
          <div class="member-wrapper">
            <vue-slick-carousel v-bind="settings">
              <div
                class="card-member"
                v-for="(value, index) in listMemberLead"
                :key="index"
              >
                <img
                  :src="getImgUrl(value.avatar)"
                  alt=""
                  height="210"
                  width="210"
                  class="avatar"
                />
                <h3>{{ value.name }}</h3>
                 <p v-if="value.dept">{{ value.dept }}</p>
                <div class="community">
                  <a
                    v-if="value.telegram != ''"
                    :href="value.telegram"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/telegram.svg"
                      :alt="value.name + '\'s Telegram'"
                      width="37"
                    />
                  </a>
                  <a
                    v-if="value.twitter != ''"
                    :href="value.twitter"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/twitter.svg"
                      :alt="value.name + '\'s Twitter'"
                      width="37"
                    />
                  </a>
                  <a
                    v-if="value.linkedin != ''"
                    :href="value.linkedin"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/linkedIn.svg"
                      :alt="value.name + '\'s Linkedin'"
                    />
                  </a>
                </div>
              </div>
            </vue-slick-carousel>
            <p class="team-name mt-16">Product Team</p>
            <vue-slick-carousel v-bind="settings">
              <div
                class="card-member"
                v-for="(value, index) in listMemberProduct"
                :key="index"
              >
                <img
                  :src="getImgUrl(value.avatar)"
                  alt=""
                  height="210"
                  width="210"
                  class="avatar"
                />
                <h3>{{ value.name }}</h3>
                <!-- <p>{{ value.dept }}</p> -->
                <div class="community">
                  <a
                    v-if="value.telegram != ''"
                    :href="value.telegram"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/telegram.svg"
                      :alt="value.name + '\'s Telegram'"
                      width="37"
                    />
                  </a>
                  <a
                    v-if="value.twitter != ''"
                    :href="value.twitter"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/twitter.svg"
                      :alt="value.name + '\'s Twitter'"
                      width="37"
                    />
                  </a>
                  <a
                    v-if="value.linkedin != ''"
                    :href="value.linkedin"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/linkedIn.svg"
                      :alt="value.name + '\'s Linkedin'"
                    />
                  </a>
                </div>
              </div>
            </vue-slick-carousel>
            <p class="team-name mt-16">Community Team</p>
            <vue-slick-carousel v-bind="settings">
              <div
                class="card-member"
                v-for="(value, index) in listMemberCommunity"
                :key="index"
              >
                <img
                  :src="getImgUrl(value.avatar)"
                  alt=""
                  height="210"
                  width="210"
                  class="avatar"
                />
                <h3>{{ value.name }}</h3>
                <!-- <p>{{ value.dept }}</p> -->
                <div class="community">
                  <a
                    v-if="value.telegram != ''"
                    :href="value.telegram"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/telegram.svg"
                      :alt="value.name + '\'s Telegram'"
                      width="37"
                    />
                  </a>
                  <a
                    v-if="value.twitter != ''"
                    :href="value.twitter"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/twitter.svg"
                      :alt="value.name + '\'s Twitter'"
                      width="37"
                    />
                  </a>
                  <a
                    v-if="value.linkedin != ''"
                    :href="value.linkedin"
                    target="_blank"
                  >
                    <img
                      src="../assets/svg/linkedIn.svg"
                      :alt="value.name + '\'s Linkedin'"
                    />
                  </a>
                </div>
              </div>
            </vue-slick-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="rocket-block">
      <img src="../assets/svg/ROBOINURocket2.svg" alt="" class="rbif-rocket" />
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import TsParticles from "@/components/TsParticles";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  title: "Robo Global Investment - Team",
  components: {
    VueSlickCarousel,
    TsParticles,
  },
  data() {
    return {
      settings: {
        arrows: false,
        swipe: false,
        infinite: false,
        slidesToShow: 3,
        initialSlide: 0,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              swipe: true,
              dots: true,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              dots: true,
              swipe: true,
              slidesToShow: 1,
              centerPadding: "0px",
            },
          },
        ],
      },
      listMemberProduct: [
        {
          name: "Tommy Nguyen",
          avatar: "tommy.png",
          // dept: "CEO/Co-Founder",
          telegram: "https://telegram.me/Firstname_Tommy",
          twitter: "https://twitter.com/firstname_tommy/",
          linkedin: "https://www.linkedin.com/in/tommy-nguyen-a83661232/",
        },
        {
          name: "Long Nguyen (Terry)",
          avatar: "terry.png",
          // dept: "Product Specialist",
          telegram: "https://telegram.me/Terry_Roboinu",
          twitter: "https://twitter.com/terry_crypto68",
          linkedin: "https://www.linkedin.com/in/long-nguyen-terry-9b6a72216/",
        },
        {
          name: "Linh Nguyen",
          avatar: "nolan.jpg",
          // dept: "Business Analyst",
          telegram: "https://telegram.me/Noolaaan",
          twitter: "",
          linkedin:
            "https://www.linkedin.com/in/linh-nguy%E1%BB%85n-ho%C3%A0ng-2486a9240/",
        },
      ],
      listMemberCommunity: [
        {
          name: "Brendan Miller",
          avatar: "brendan_miller.jpg",
          // dept: "Community Manager",
          telegram: "https://telegram.me/redpillgoku",
          twitter: "https://twitter.com/redpillgoku",
          linkedin: "",
        },
        {
          name: "Gabriele Garavaglia",
          avatar: "GabrieleGaravaglia.png",
          // dept: "Business Analyst",
          telegram: "https://telegram.me/Garagab",
          twitter: "https://twitter.com/garagab_",
          linkedin:
            "https://www.linkedin.com/in/gabriele-garavaglia-570930159/",
        }
      ],
      listMemberLead: [
        {
          name: "Vy Pham",
          avatar: "msvy.png",
          dept: "CEO/Co-Founder",
          telegram: "https://telegram.me/msvy_crypto",
          twitter: "https://twitter.com/msvy_crypto",
          linkedin: "https://www.linkedin.com/in/phamtuongvy/",
        },
      ],
    };
  },
  methods: {
    getImgUrl(img) {
      return require("../assets/img/" + img);
    },
  },
};
</script>

<style>
</style>
