<template>
  <div class="header-wrapper" :class="{navbarOpen: this.$store.state.showNavBar}">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <router-link tag="a" :to="{name: 'homepage'}" class="navbar-brand">
          <img src="../assets/svg/header-logo-tm.svg" alt="RoboInu Logo" width="217" height="31">
        </router-link>
        <button class="navbar-toggler" @click="showNavBar">
          <img src="../assets/svg/navbar-btn.svg" alt="Open Menu" class="i-open">
          <img src="../assets/svg/close.svg" alt="Close Menu" class="i-close clicked">
        </button>
        <div class="collapse navbar-collapse ml-auto flex-grow-0">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a to="" href="https://dashboard.roboglobal.info" tag="a" class="nav-link" target="_blank">
                Dashboard
              </a>
            </li>
            <li
                class="nav-item dropdown-cus"
                @mouseup="showCommunityDropDown = !showCommunityDropDown"
                @mouseover="showCommunityDropDown = true"
                @mouseleave="showCommunityDropDown = false"
            >
              <router-link to="#" tag="a" class="nav-link">
                Community
              </router-link>
              <div class="dropdown-menu1" :class="{show: showCommunityDropDown}">
                <a class="dropdown-item" href="https://t.me/robo_inu" target="_blank">Telegram</a>
                <a class="dropdown-item" href="https://twitter.com/RGI_info" target="_blank">Twitter</a>
                <a class="dropdown-item" href="https://www.reddit.com/r/roboinu/" target="_blank">Reddit</a>
                <a class="dropdown-item" href="https://www.facebook.com/groups/318598893018719"
                   target="_blank">Facebook</a>
              </div>
            </li>
            <li
                class="nav-item dropdown-cus"
                @mouseup="showFoundationDropDown = !showFoundationDropDown"
                @mouseover="showFoundationDropDown = true"
                @mouseleave="showFoundationDropDown = false"
            >
              <router-link to="#" tag="a" class="nav-link">
                Foundation
              </router-link>
              <div class="dropdown-menu1" :class="{show: showFoundationDropDown}">
                <router-link tag="a" :to="{name: 'roadmap'}" class="dropdown-item">Roadmap</router-link>
                <router-link class="dropdown-item" tag="a" :to="{name: 'tokenomics'}">Tokenomics</router-link>
                <router-link :to="{name: 'whitepaper'}" tag="a" class="dropdown-item">WhitePaper</router-link>

                <a class="dropdown-item" href="https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"
                   target="_blank">Contract</a>
              </div>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'team'}" tag="a" class="nav-link">
                Team
              </router-link>
            </li>
          </ul>
          <a class="btn btn-buy ml-60"
             href="https://dex.roboglobal.info/swap?outputCurrency=0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"
             target="_blank">Buy $RBIF</a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-bar",
  data() {
    return {
      showCommunityDropDown: false,
      showFoundationDropDown: false,
    }
  },
  created() {
    this.$eventBus.$on('closeNavBar', this.showNavBar)
  },
  destroyed() {
    this.$eventBus.$off('closeNavBar', this.showNavBar)
  },
  methods: {
    showNavBar() {
      if (!this.$store.state.showNavBar) {
        document.getElementsByTagName('body')[0].style.overflow = "hidden";
        document.getElementsByClassName("i-open")[0].classList.add("clicked");
        document.getElementsByClassName("i-close")[0].classList.remove("clicked");
        this.$eventBus.$emit('showNavBar', this.$store.state.showNavBar)
        this.$store.commit('setShowNavBar', true)
        return;
      }
      document.getElementsByTagName('body')[0].style.overflow = "auto";
      document.getElementsByClassName("i-close")[0].classList.add("clicked");
      document.getElementsByClassName("i-open")[0].classList.remove("clicked");
      this.$eventBus.$emit('showNavBar', this.$store.state.showNavBar)
      this.$eventBus.$emit('returnMainNav')
      this.$store.commit('setShowNavBar', false)
    }
  }
}
</script>

<style>
.navbarOpen {
  background: black;
  opacity: .9;
}
</style>
