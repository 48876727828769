<template>
  <div>
    <ModalNoClose>
      <div slot="header" class="w-100">
        <img src="../../assets/img/special-announcement.png" alt="special-announcement" class="modal-img-header">
      </div>
      <div slot="body" class="modal-body-detail">
        <p>
          Hello everyone,
        </p>
        <p>
          No more Robo Inu, no more CEO, no more leader. Community Take Over forever.
        </p>
        <p>
          Robo Inu ($RBIF) on ERC20 will be migrated to Musketeers AI ($MAI) on the Solana network.
        </p>
        <p>
          If you are a former $RBIF holder, please claim your $MAI tokens:
        </p>
        <div class="d-flex justify-content-between align-items-center modal-border p-0">
          <span class="p-2 text-break">{{ token }}</span>
          <div class="icon-border p-2">
            <img v-if="!isCopySuccess" src="../../assets/svg/copy.png" alt="copy" @click="handleCopy"
                 class="copy-icon"/>
            <div class="position-relative d-flex flex-column align-items-center">
              <img v-if="isCopySuccess" src="../../assets/svg/copy-done.png" alt="copied" @click="handleCopy"
                   class="copy-icon"/>
              <span v-if="isCopySuccess" class="position-absolute copied-text">Copied</span>
            </div>
          </div>
        </div>
        <p class="mt-3">Please claim your $MAI tokens at:</p>
        <div class="d-flex justify-content-between align-items-center modal-border p-0">
          <span class="p-2 text-break"> <a href="https://dashboard.roboglobal.info/" target="_blank">https://dashboard.roboglobal.info</a></span>
        </div>
        <p class="mt-3">Join the discussion and share ideas in the Telegram group by the community here:</p>
        <div class="d-flex justify-content-between align-items-center p-0 modal-border">
          <span class="pl-2">Musketeers AI channel. Join <span class="text-blue">@MusketeersAI</span></span>
          <a href="https://t.me/MusketeersAI" target="_blank" class="ml-2 view-in-tele">
            VIEW IN TELEGRAM
          </a>
        </div>
        <p class="mt-3">Since this is a Community Take Over, please be cautious of any scammers activities.</p>
      </div>

      <div slot="footer" class="modal-footer-detail">
      </div>
    </ModalNoClose>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import ModalNoClose from "@/components/modal/ModalNoClose.vue";

export default Vue.extend({
  components: {
    ModalNoClose,
  },
  data() {
    return {
      token: "4S9pHgHRhzJEudbK8zDhypS8xwCEFBnPw8K79zsWv1et",
      isCopySuccess: false,
    };
  },
  updated() {
  },
  methods: {
    ...mapActions([]),

    handleCopy() {
      navigator.clipboard.writeText(this.token).then(() => {
        this.isCopySuccess = true;
        setTimeout(() => {
          this.isCopySuccess = false;
        }, 2000);
      }).catch(err => {
        console.error('Không thể sao chép token: ', err);
      });
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState({}),
  },
});
</script>
