<template>
  <div class="navbar-modal-wrapper">
    <div class="bg"></div>
    <div class="container">
      <div class="navbar-collapse open" ref="main">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="https://dashboard.roboglobal.info" target="_blank" class="nav-link">
              Dashboard
            </a>
          </li>
          <li
              class="nav-item"
              @click="showCommunity"
          >
            <a href="#" class="nav-link">
              Community
            </a>
          </li>
          <li
              class="nav-item"
              @click="showFoundation"
          >
            <a href="#" class="nav-link">
              Foundation
            </a>
          </li>
          <li class="nav-item"
              @click="closeNavBar"
          >
            <router-link :to="{name: 'team'}" tag="a" class="nav-link">
              Team
            </router-link>
          </li>
        </ul>
        <a class="btn btn-buy"
           href="https://dex.roboglobal.info/swap?outputCurrency=0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"
           target="_blank">Buy $RBIF</a>
      </div>
      <div class="navbar-collapse" ref="community">
        <ul class="navbar-nav">
          <li class="nav-item" @click="showMainNav">
            <a class="nav-link title" href="#"><img src="../../assets/svg/sort-left.svg" style="vertical-align: unset;"> &nbsp;Community</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://t.me/robo_inu" target="_blank">Telegram</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://twitter.com/RoboInuFinance" target="_blank">Twitter</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.reddit.com/r/roboinu/" target="_blank">Reddit</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.facebook.com/groups/318598893018719"
               target="_blank">Facebook</a>
          </li>
        </ul>
      </div>
      <div class="navbar-collapse" ref="foundation">
        <ul class="navbar-nav">
          <li class="nav-item" @click="showMainNav">
            <a class="nav-link title" href="#"><img src="../../assets/svg/sort-left.svg" style="vertical-align: unset;"> &nbsp;Foundation</a>
          </li>
          <li class="nav-item" @click="closeNavBar">
            <router-link tag="a" :to="{name: 'roadmap'}" class="nav-link">Roadmap</router-link>
          </li>
          <li class="nav-item" @click="closeNavBar">
            <router-link class="nav-link" tag="a" :to="{name: 'tokenomics'}">Tokenomics</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
               :to="{name: 'whitepaper'}"
               target="_blank">Whitepaper</router-link></li>
          <li class="nav-item">
            <router-link class="nav-link"
               :to="{name: 'term'}"
               target="_blank">ToC</router-link></li>
          <li class="nav-item">
            <a class="nav-link" href="https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"
               target="_blank">Contract</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://drive.google.com/file/d/1uSzDNXbB5b4mGql-H7SrL4CETy0ODQSC/view"
               target="_blank">Press Release</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  created() {
    this.$eventBus.$on('showNavBar', this.showNavBar)
    this.$eventBus.$on('returnMainNav', this.showMainNav)
  },
  destroyed() {
    this.$eventBus.$off('showNavBar', this.showNavBar)
    this.$eventBus.$off('returnMainNav', this.showMainNav)

  },
  methods: {
    showNavBar(e) {
      if (!e) {
        document.getElementsByClassName("navbar-modal-wrapper")[0].style.height = "100%"
        return;
      }
      document.getElementsByClassName("navbar-modal-wrapper")[0].style.height = "0%"
    },
    showMainNav(){
      setTimeout(()=>{
        this.$refs.main.classList.add("open");
      },500)
      this.$refs.community.classList.remove("open");
      this.$refs.foundation.classList.remove("open");
    },
    showCommunity(){
      this.$refs.main.classList.remove("open");
      setTimeout(()=>{
        this.$refs.community.classList.add("open");
      },500)
      this.$refs.foundation.classList.remove("open");
    },
    showFoundation(){
      this.$refs.main.classList.remove("open");
      this.$refs.community.classList.remove("open");
      setTimeout(()=>{
        this.$refs.foundation.classList.add("open");
      },500)
    },
    closeNavBar(){
      this.$eventBus.$emit('closeNavBar')
    }
  }
}
</script>
