<template>
  <div>
    <div class="why-robo-block">
      <h5 class="sub-title">WHY ROBO INU</h5>
      <h4 class="hight-light">
        We create <span>value</span> yet to be seen in other projects.
      </h4>
      <p class="text-block">
        From mainstream financial products to breakthrough technology
        applications, we prioritise one goal - to maximize value.
      </p>
    </div>
    <div class="sub-brand">
      <vue-slick-carousel v-bind="settingsSubBrand">
        <div class="card" v-for="item in items" :key="item.title">
          <div class="head"></div>
          <div class="content">
            <img :src="getSvgUrl(item.img)" :alt="item.title"/>
            <h5 class="title mt-2">{{ item.title }}</h5>
            <p class="description mt-2">
              {{ item.description }}
            </p>
            <a class="read-details" @click="onClickDetail(item)"
            >READ DETAILS
              <img
                  src="../../assets/svg/right-arrow.svg"
                  alt="right-arrow"
                  width="12"
                  height="12"
              /></a>
            <div v-if="item.url">
              <a class="btn btn-color btn-trans mt-3" :href="item.url" target="_blank">
                {{ item.action }}
              </a>
            </div>
            <div v-else>
              <button class="btn btn-color btn-trans mt-3" disabled>
                {{ item.action }}
              </button>
            </div>
          </div>
        </div>
      </vue-slick-carousel>
    </div>
    <modal-detail
        :isShowModal="isShowModal"
        @onClose="onClose"
        :item="itemSelected"
    />
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ModalDetail from "@/components/ModalDetail.vue";

export default {
  name: "why-robo-wrapper",
  components: {
    VueSlickCarousel,
    ModalDetail,
  },
  data() {
    return {
      isShowModal: false,
      itemSelected: {},
      items: [
        {
          title: "RGI Wallet",
          summary: "Personal Wallet on Multi-chain",
          description:
              "A personal wallet to store, track and manage cryptocurren...",
          descriptionDetail: `A personal wallet which has the ability to store all currencies, $RBIF tokens, and other cryptocurrencies as well.<br>
             - The wallet also has the ability to bridge different cross-chain networks with the lowest transaction fees.<br>
             - Download Now: <a href="https://apps.apple.com/vn/app/rgi-wallet/id1659875563">App Store</a> | <a href="https://play.google.com/store/apps/details?id=com.roboglobal.wallet">CH Play</a> | <a href="https://chromewebstore.google.com/detail/rgi-wallet/jhckpnnnpdipkakahjjhlpnieglnjcic">Chrome Store</a> `,
          img: "ROBOINU-RGB-RoboWallet.svg",
          action: "Released",
        },
        {
          title: "RoboLaunchpad",
          summary: "Robo’s Token Launch Platform",
          description:
              "Create an unlimited connection in terms of financial technology...",
          descriptionDetail: `An incubator for potential projects with real user cases. All these projects are checked and moderated by the Robo Inu Finance Team. There is a guarantee that no scam projects will be released on the Robo Launchpad.`,
          img: "ROBOINU-RGB-RoboLaunchpad.svg",
          action: "COMING SOON",
        },
        {
          title: "RoboNFT Marketplace",
          summary: "Explore, collect and exchange NFTs",
          description: "Will be a launch pad to support startup projects...",
          descriptionDetail: `A high-valued digital asset exchange which has daily auctions (NFT Products) integrated with RoboWallet where you can own any digital asset value with just a click of a mouse. RoboWallet will be built with the ability to hold the collectibles.`,
          img: "ROBOINU-RGB-RoboMarket.svg",
          action: "COMING SOON",
        },
        {
          title: "RoboVentures",
          summary: "EARN MONEY",
          description: "Help holders earn money by holding $RBIF tokens...",
          descriptionDetail: `Help holders earn money by holding $RBIF tokens...`,
          img: "ROBOINU-RGB-RoboVentures.svg",
          action: "COMING SOON",
        },
        {
          title: "RoboDAO",
          summary: "Stake RBIF - Vote - Earn Rewards",
          description:
              "For holders who stake RBIF tokens to vote on governance...",
          descriptionDetail: `RoboDAO is a decentralized autonomous organization that is implemented to promote the autonomy given to the community. <br> RoboDAO is a platform where holders of $RBIF tokens can vote on the governance proposal which can contribute to the success of RGI's ecosystem.`,
          img: "robo-dao.svg",
          action: "Released",
          countDownToTime: new Date("Jan 3, 2023 00:00:00").getTime(),
          url: "https://dashboard.roboglobal.info/"
        },
        {
          title: "RoboEx",
          summary: "A Next Evolution DeFi Exchange built for the community",
          description: "A next evolution DeFi Exchange on Ethereum Chain...",
          descriptionDetail: `RoboEx is a decentralized exchange that allows holders of $RBIF tokens to trade like an order book (with limit orders when buy or sell) while paying zero fees for using the platform. Holders will only need to pay for the network fee. <br>
        RoboEx and RoboWallet will be connected all in one app while at the same time, users can have access to other needs such as shopping, traveling, etc. RoboEx helps to complete the entire product ecosystem and bring many benefits to our #RoboWarriors community.`,
          img: "robo-ex.svg",
          action: "Released",
          url: "https://dex.roboglobal.info/"
        },
      ],
      settingsSubBrand: {
        // arrows: false,
        // swipe: false,
        // infinite: false,
        // slidesToShow: 4,
        // initialSlide: 0,
        // slidesToScroll: 1,
        // centerMode: true,
        arrows: false,
        swipe: false,
        infinite: false,
        slidesToShow: 3,
        // speed: 500,
        rows: 2,
        slidesPerRow: 1,
        centerMode: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              rows: 1,
              swipe: true,
              dots: true,
              centerMode: false,
              slidesToShow: 2,
              infinite: true,
              autoplay: true,
              speed: 500,
              autoplaySpeed: 5000,
              cssEase: "linear",
            },
          },
          {
            breakpoint: 576,
            settings: {
              rows: 1,
              swipe: true,
              dots: true,
              slidesToShow: 1,
              centerPadding: "0px",
              infinite: true,
              autoplay: true,
              speed: 500,
              autoplaySpeed: 5000,
              cssEase: "linear",
            },
          },
        ],
      },
    };
  },
  methods: {
    onClose() {
      this.isShowModal = false;
    },
    onClickDetail(itemSelected) {
      this.itemSelected = itemSelected;
      this.isShowModal = true;
    },
    getSvgUrl(svg) {
      return require("@/assets/svg/" + svg);
    },
  },
};
</script>
