var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-modal-wrapper"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"container"},[_c('div',{ref:"main",staticClass:"navbar-collapse open"},[_c('ul',{staticClass:"navbar-nav"},[_vm._m(0),_c('li',{staticClass:"nav-item",on:{"click":_vm.showCommunity}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(" Community ")])]),_c('li',{staticClass:"nav-item",on:{"click":_vm.showFoundation}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(" Foundation ")])]),_c('li',{staticClass:"nav-item",on:{"click":_vm.closeNavBar}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'team'},"tag":"a"}},[_vm._v(" Team ")])],1)]),_c('a',{staticClass:"btn btn-buy",attrs:{"href":"https://dex.roboglobal.info/swap?outputCurrency=0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b","target":"_blank"}},[_vm._v("Buy $RBIF")])]),_c('div',{ref:"community",staticClass:"navbar-collapse"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",on:{"click":_vm.showMainNav}},[_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{ref:"foundation",staticClass:"navbar-collapse"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",on:{"click":_vm.showMainNav}},[_vm._m(6)]),_c('li',{staticClass:"nav-item",on:{"click":_vm.closeNavBar}},[_c('router-link',{staticClass:"nav-link",attrs:{"tag":"a","to":{name: 'roadmap'}}},[_vm._v("Roadmap")])],1),_c('li',{staticClass:"nav-item",on:{"click":_vm.closeNavBar}},[_c('router-link',{staticClass:"nav-link",attrs:{"tag":"a","to":{name: 'tokenomics'}}},[_vm._v("Tokenomics")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'whitepaper'},"target":"_blank"}},[_vm._v("Whitepaper")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'term'},"target":"_blank"}},[_vm._v("ToC")])],1),_vm._m(7),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://dashboard.roboglobal.info","target":"_blank"}},[_vm._v(" Dashboard ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link title",attrs:{"href":"#"}},[_c('img',{staticStyle:{"vertical-align":"unset"},attrs:{"src":require("../../assets/svg/sort-left.svg")}}),_vm._v("  Community")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://t.me/robo_inu","target":"_blank"}},[_vm._v("Telegram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://twitter.com/RoboInuFinance","target":"_blank"}},[_vm._v("Twitter")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://www.reddit.com/r/roboinu/","target":"_blank"}},[_vm._v("Reddit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://www.facebook.com/groups/318598893018719","target":"_blank"}},[_vm._v("Facebook")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link title",attrs:{"href":"#"}},[_c('img',{staticStyle:{"vertical-align":"unset"},attrs:{"src":require("../../assets/svg/sort-left.svg")}}),_vm._v("  Foundation")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b","target":"_blank"}},[_vm._v("Contract")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://drive.google.com/file/d/1uSzDNXbB5b4mGql-H7SrL4CETy0ODQSC/view","target":"_blank"}},[_vm._v("Press Release")])])
}]

export { render, staticRenderFns }